import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PostCard from "./layout/adviserPostCard";
import { Grid, Button, Typography, useMediaQuery } from "@material-ui/core";
import WhiteIconLeft from "./icons/WhiteIconLeft.svg";
import WhiteIconRight from "./icons/WhiteIconRight.svg"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";
import Container from "../../container.js"

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const useStyles = makeStyles((theme) => ({
  swiperSlide: {
    overflow: "auto",
    backgroundColor: "#ffffff",
    // padding: '16px',
  },
  aboutMe: {
    marginBottom: "16px",
    marginTop: "40px",
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: "40px",
    lineHeight: "50px",
  },
  cardList: {
    width: "100%",
  },
  cardsContainer: {
    height: "100%",
    marginTop: "0 !important",
    marginBottom: "0 !important",
    paddingTop: '24px'
  },
  navIconLeftContainer: {
    display: "flex",
    justifyContent: "end",
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
    "@media (min-width: 1625px)": {
      marginRight: "40px",
    },
  },
  navIconRightContainer: {
    display: "flex",
    justifyContent: "start",
    marginRight: "auto",
    marginLeft: "auto",
    cursor: "pointer",

    "@media (min-width: 1625px)": {
      marginLeft: "40px",
    },
  },
  navIconLeft: {
    height: "48px",
    width: "48px",
  },
  navIconRight: {
    height: "48px",
    width: "48px",
  },
  bodyContainer: {
    "@media (min-width: 1423px)": {
      maxWidth: "1170px",
    },
  },
  mobileNavContainer: {
    cursor: "pointer",
    display: "flex",
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    justifyContent: "center",
  },
  mobileLeftIcon: {
    "@media (min-width: 695px)": {
      paddingRight: "6px",
    },
  },
  mobileRightIcon: {
    "@media (min-width: 695px)": {
      paddingLeft: "6px",
    },
  },
  swiperAnchorTag: {
    textDecoration: "none",
    color: "inherit",
    display: "block",
    height: "100%",
  },
}));

function AdviserPosts(props) {
  const { dashboardId, arrows, autoscroll } = props;
  const [posts, setPosts] = useState([]);

  const [rightArrow, useRightArrow] = useState(null);
  const [leftArrow, useLeftArrow] = useState(null);

  const [mobileRightArrow, useMobileRightArrow] = useState(null);
  const [mobileLeftArrow, useMobileLeftArrow] = useState(null);

  const matchesDesktop = useMediaQuery("(min-width:960px)");
  const matchesMobile = useMediaQuery("(max-width: 950px)");
  const [conversations, setConversations] = useState([]);

  const classes = useStyles();


  useEffect(() => {
    (async () => {
      var forumId = await getForumUserId();
      var userSlug = await getUserSlugByForumId(forumId);
      var postsResult = await getUserPosts(userSlug);


      if (postsResult && postsResult.posts && postsResult.posts.length > 0) {
        var conversations = new Array();

        for (var i = 0; i < postsResult.posts.length; i++) {
          var conversation = {
            content: postsResult.posts[i].content,
            timestampISO: postsResult.posts[i].timestampISO,
            replies: postsResult.posts[i].replies,
            title: postsResult.posts[i].topic.title,
            slug: postsResult.posts[i].topic.slug,
            user: postsResult.posts[i].user,
            pid: postsResult.posts[i].pid
          };

          conversations.push(conversation);
        }


        setPosts(conversations);
      }
    })();
  }, []);

  useEffect(() => {
    if (posts) {
      //console.log('posts: ', posts);
    }
  }, [posts]);

  var getForumUserId = async function () {
    var response = await fetch(process.env.GATSBY_QUESTIONAPI + '/ForumIdByUserId/GetUserId', {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dashboardId)
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson }).catch((err) => {
        console.log("err-",err)
      });

    if (response && response.Success) {

      var userId = response.Result;

      return userId;
    }

    return null;
  }

  var getUserPosts = async function (userSlug) {
    var nodeBbUrl = process.env.GATSBY_NODEBB_URL;

    var responseJson = await fetch(nodeBbUrl + "user/" + userSlug + '/posts?_uid=1', {
      method: "GET",
      headers: {
        Authorization: "Bearer " + process.env.NODEBB_MASTER_API_TOKEN,
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      }).catch((err) => {
        console.log("err-", err)
      });

    return responseJson;
  }

  var getUserSlugByForumId = async function (forumId) {
    var nodeBbUrl = process.env.GATSBY_NODEBB_URL;

    var responseJson = await fetch(nodeBbUrl + "user/uid/" + forumId + '?_uid=1', {
      method: "GET",
      headers: {
        Authorization: "Bearer " + process.env.NODEBB_MASTER_API_TOKEN,
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      }).catch((err) => {
        console.log("err-", err)
      });

    if (responseJson && responseJson.userslug) {
      return responseJson.userslug;
    } else {
      return null;
    }
  }


  if (arrows === true || autoscroll === true) {
    return (
      <>
        {posts && posts.length > 0 && <Container>
          <Typography variant="h2" className={classes.aboutMe}>
            Have a look at my forum responses
          </Typography>
        </Container>}
        {posts && posts.length > 0 && <Grid className={classes.cardList}>
          {/* Desktop Swiper */}
          {matchesDesktop && (
            <Grid container item alignItems="center" justify="space-between">
              <Grid item xs={0} md={1} className={classes.navIconLeftContainer}>
                {arrows && (
                  <img
                    ref={useLeftArrow}
                    className={classes.navIconLeft}
                    src={WhiteIconLeft}
                  ></img>
                )}
              </Grid>
              <Grid item xs={11} md={10} className={classes.bodyContainer}>
                <Grid container className={classes.cardsContainer}>
                  <Swiper
                    modules={[Navigation, Pagination, Autoplay, A11y]}
                    slidesPerView={3}
                    // observer={true}
                    // resizeObserver={true}
                    // autoplay={autoscroll}
                    spaceBetween={24}
                    updateOnWindowResize={true}
                    navigation={{
                      nextEl: rightArrow,
                      prevEl: leftArrow,
                    }}
                    autoplay={
                      autoscroll
                        ? {
                          pauseOnMouseEnter: true,
                          disableOnInteraction: false,
                          delay: 2000,
                        }
                        : false
                    }
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      659: {
                        slidesPerView: 3,
                      },
                      959: {
                        slidesPerView: 3,
                      }
                    }}
                  >
                    {posts.map(
                      ({ timestampISO, slug, content, title, replies, user, pid }, index) => {

                        return (
                          <SwiperSlide className={classes.swiperSlide}>
                            {" "}
                            <a className={classes.swiperAnchorTag}  href={`/forum/posts/${slug}/#${pid}`}>
                              <PostCard
                                id={index}
                                timestampISO={timestampISO}
                                replies={replies}
                                content={content}
                                title={title}
                                user={user}
                              />
                            </a>
                          </SwiperSlide>
                        );
                      }
                    )}
                  </Swiper>
                  {matchesMobile && (
                    <div className={classes.mobileNavContainer}>
                      <img ref={useMobileLeftArrow} src={WhiteIconLeft}></img>
                      <img ref={useMobileRightArrow} src={WhiteIconRight}></img>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={0} md={1} className={classes.navIconRightContainer}>
                {arrows && (
                  <img
                    ref={useRightArrow}
                    className={classes.navIconRight}
                    src={WhiteIconRight}
                  ></img>
                )}
              </Grid>
            </Grid>
          )}
          {/* Mobile swiper  */}
          {matchesMobile && (
            <Grid container item alignItems="center" justify="space-between">
              <Grid
                item
                xs={0}
                md={1}
                className={classes.navIconLeftContainer}
              ></Grid>
              <Grid item xs={11} md={10} className={classes.bodyContainer}>
                <Grid container spacing={0} className={classes.cardsContainer}>
                  {matchesDesktop}
                  <Swiper
                    modules={[Navigation, Pagination, Autoplay, A11y]}
                    slidesPerView={1}
                    observer={true}
                    resizeObserver={true}
                    updateOnWindowResize={true}
                    navigation={{
                      nextEl: mobileRightArrow,
                      prevEl: mobileLeftArrow,
                    }}
                    autoplay={
                      autoscroll
                        ? {
                          pauseOnMouseEnter: true,
                          disableOnInteraction: false,
                        }
                        : false
                    }
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      659: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      959: {
                        slidesPerView: 3,
                      }
                    }}
                  >
                    {posts.map(
                      ({ timestampISO, slug, content, title, replies, user, pid }, index) => {
                        return (
                          <SwiperSlide className={classes.swiperSlide}>
                            {" "}
                            <a className={classes.swiperAnchorTag}  href={`/forum/posts/${slug}/${pid}`}>
                              <PostCard
                                user={user}
                                id={index}
                                timestampISO={timestampISO}
                                replies={replies}
                                content={content}
                                title={title}
                              />

                            </a>
                          </SwiperSlide>
                        );
                      }
                    )}
                  </Swiper>
                  {arrows && (
                    <div className={classes.mobileNavContainer}>
                      <img
                        ref={useMobileLeftArrow}
                        src={WhiteIconLeft}
                        className={classes.mobileLeftIcon}
                      ></img>
                      <img
                        ref={useMobileRightArrow}
                        src={WhiteIconRight}
                        className={classes.mobileRightIcon}
                      ></img>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={0}
                md={1}
                className={classes.navIconRightContainer}
              ></Grid>
            </Grid>
          )}
        </Grid>}
      </>    );
  }
}

export default AdviserPosts;
