import Container from "./container";
import ContainerForPages from "./containerForPages";
import PortableText from "./portableText";
import React, { useState, useEffect } from "react";
import { getNumberOfLikes, getAdviserQuestions, getUserObject } from "../lib/helpers";
import WidgetComponent from "./sidebarComponents/widgetComponent";
import AdviserPageReviews from "./adviserPageReviews";
import AdviserLikes from "./landingPageComponents/adviserLikes";
import AdviserLikesMobile from "./landingPageComponents/adviserLikesMobile";
import {
  AppBar,
  Grid,
  Typography,
  Toolbar,
  ListItem,
  List,
  IconButton,
  Hidden,
  Drawer,
  CssBaseline,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useStaticQuery, graphql } from "gatsby";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../.././client-config";
import serializers from "./serializers";
import ButtonIcon from "./icon/ArrowRightSquare.svg";
import LocationIcon from "./icon/VisibleLocationIcon.svg";
import HeartIcon from "./icon/HeartIcon.svg";
import TickYesIcon from "./icon/VisibleTickYesIcon.svg";
import ContainerForSVG from "./containerForSVG";
import BmAwards from "./icon/BmAwards.svg";
import Aberdeen from "./icon/Aberdeen.svg";
import Trophy from "./icon/Trophy.svg";
import RateAdviserDialog from "./popups/rateAdviserPopup";
import Dialog from "./popups/contactPopup";
import FooterDisclaimer from "./footer-disclaimer";
import PeopleAward from "./people-award";
import AdviserCompany from "./adviserCompany";

import FilteredQuestionsAdviser from "../components/filteredQuestions/filtered-questions-adviser";
import { Figure } from "./Figure";
import { formatRelative } from "date-fns";
import AdviserPosts from "../components/forumComponents/adviserPosts/adviserPosts";
import forumBreadcrumb, {getAdviserPageBreadcrumbs} from "./forumComponents/forumBreadcrumb";
import ForumBreadcrumb from "./forumComponents/forumBreadcrumb";

const useStyles = makeStyles((theme) => ({
  linkTextNotActive: {
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: '20px',
    fontFamily: 'Poppins',
    textTransform: 'uppercase'
  },
  linkTextActive: {
    color: "#262641",
    fontSize: "14px",
    fontWeight: "600",
  },
  linkMargin: {
    marginTop: "15px",
  },
  grey: {
    paddingTop: "15px",
    paddingBottom: "30px",
    // paddingLeft: "30px",
    // paddingRight: "30px"
  },
  greyInner: {
    paddingTop: "20px",
    paddingBottom: "30px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  dot: {
    color: "#ffffff",
    marginLeft: "7px",
    marginRight: "7px",
  },
  imageHolder: {
    // maxWidth: "216px",
    // maxHeight: "216px",
    paddingRight: "24px",
    // [theme.breakpoints.down("xs")]: {
    //   maxWidth: "72px",
    //   maxHeight: "72px",
    // },
  },
  imageContainer: {
    //background: 'linear-gradient(90deg, #FBC2EB 0%, #A6C1EE 100%)',
    //opacity: '0.35'
    borderRadius: "10px",
  },
  profile: {
    direction: "row",
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 4px rgb(64 64 64 / 10%)",
    padding: "24px",
    border: "1px solid #D4D4D4",
    borderRadius: "16px",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      padding: "16px",
    },
  },
  sidebarTitle: {
    color: "#FFFFFF",
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px'
  },
  sidebarSubtitle: {
    paddingBottom: "12px",
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    color: "#6464FF",
  },
  sidebarText: {
    color: "#8C8CA1",
    lineHeight: "20px",
    fontWeight: "normal",
    fontSize: "14px",
  },
  sidebarText80Percent: {
    lineHeight: "20px",
    fontSize: "14px",
    fontFamily: 'Poppins',
    width: "88%",
    fontWeight: 400,
    color: "#3C465F",
  },
  iconSidebar: {
    paddingRight: "8px",
  },
  sidebarMenuItem: {
    paddingBottom: "8px",
  },
  sidebar: {
    backgroundColor: "#ffffff",
    height: "fit-content",
    boxSizing: "border-box",
    border: "1px solid #D4D4D4",
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
      width: "100%",
    },
  },
  sideBarAwards: {
    backgroundColor: "#ffffff",
    height: "fit-content",
    // padding: "1.25rem",
    border: "1px solid #D4D4D4",
    boxSizing: "border-box",
    width: "300px",
    marginTop: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      marginTop: "1rem",
      width: "100%",
    },
  },
  mobileFullwidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content: {
    width: "calc(100% - 370px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "20px",
    },
  },
  sidebarBlock: {
    padding: "16px 16px 8px 16px",
  },
  aboutMe: {
    marginBottom: "16px",
    marginTop: "40px",
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: "40px",
    lineHeight: "50px",
    color: "#3C465F",
  },
  jobTitle: {
    color: "#6464FF",
    paddingBottom: "16px",
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
    textTransform: 'uppercase'
  },
  jobTitleLessPaddingBottom: {
    color: "#6464FF",
    paddingBottom: "13px",
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
    textTransform: 'uppercase'
  },
  likes: {
    color: "#8C8CA2",
    marginRight: "17px",
  },
  amountOfLikes: {
    marginLeft: "7px",
  },
  likesButtonContainer: {
    width: "100%",
    marginTop: "9px",
  },
  profileText: {
    justifyContent: "space-between",
    direction: "column",
  },
  nameText: {
    marginBottom: "8px",
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '700',
    color: "#3C465F",
},
  contactMeButton: {
    borderRadius: "8px",
    width: "220px",
    height: "56px",
    backgroundColor: "#DBE3EF",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
      marginTop: "28px",
    },
  },
  contactMeButtonText: {
    marginRight: "11px",
    color: "#50586C",
  },
  location: {
    marginLeft: "13px",
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    color: "#3C465F",
    textTransform: 'uppercase',
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      marginLeft: "7px",
    },
  },
  contactRateContainer: {
    marginTop: "2.5em",
    marginBottom: "1em",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  widgetsContainer: {
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
    },
  },
  adContent: {
    width: "100%",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileSixtyFive: {
    [theme.breakpoints.down("sm")]: {
      width: "65%",
    },
  },
  likesButtonContainerNoPaddingTop: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  likesButtonContainerLessPaddingTop: {
    width: "100%",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactMeButtonContainer: {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    "& div": {
      "& button": {
        marginTop: 0,
        "& span": {
          [theme.breakpoints.down("md")]: {
            "& img": {
              display: "none",
            },
          },
          "& span": {
            [theme.breakpoints.down("sm")]: {
              marginRight: 0,
            },
          },
        },
      },
    },
  },
  awardImgContainer: {
    background: "#F7F5E7",
    border: "0.5px solid #D4D4D4",
  },
  sponsor: {
    width: "7.75rem",
    height: "4rem"
  },
  awardIcon: {
    width: "6.125rem",
    height: "4rem"
  },
  accoladeContainer: {
    marginBottom: "20px",
    background: "#FFFFFF",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    "& ul": {
      margin: "0",
      paddingTop: "6px",
      paddingBottom: "6px",
      "& li": {
        listStyleImage: `url(${Trophy})`,
        paddingTop: "6px",
        marginBottom: "6px",
        left: "9px",
        listItem: {
          position: "relative",
          left: "9px"
        }
      },
    }
  },
  sideBarCompany: {
    backgroundColor: "#ffffff",
    height: "fit-content",
    borderRadius: "12px",
    border: "1px solid #DBE3EF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgb(64 64 64 / 10%)",
    width: "300px",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "1.5rem"
    }

  },
  sideBarCompanyNoMarginTop: {
    backgroundColor: "#ffffff",
    height: "fit-content",
    borderRadius: "12px",
    border: "1px solid #DBE3EF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgb(64 64 64 / 10%)",
    width: "300px",
    marginTop: "0",
    [theme.breakpoints.up("md")]: {
      marginTop: "0"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    // [theme.breakpoints.down("md")]: {
    //   minWidth: "335px"
    // }
    // },
    // itemsRightContainer: {
    //   minWidth: "330px",
  },
  itemsRightContainer: {
    [theme.breakpoints.down("sm")]: {
      flex: "0 0 100%"
      // maxWidth: "572px",
      // width: "572px"
    },
  },
  peopleBioWrapper: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
   "& p, ul, ol, li, h1, h2, h3, h4, h5, h6": {
      color: "#3C465F !important",
    },
    '& h1, h2, h3, h4, h5, h6': {
      fontFamily: 'Poppins'
    }
  },
  headerContainer: {
    padding: '12px 16px',
    background: "#3C465F",
    boxShadow: "inset 0px -1px 0px rgba(170, 169, 228, 0.3)",
  }
}));

let placeholders = {
  iLikeHelpingPeopleOn: [
    "Placeholder: Inheritance planning",
    "Placeholder: Pensions – I’m retired ",
    "Placeholder: Mortgages",
  ],
  qualifications: [
    "Placeholder: Diploma in Financial Planning",
    "Placeholder: PIMFA ESG Academy",
    "Placeholder: Chartered Financial Planner",
  ],
  amountOfLikes: "100",
};

function BlogPost(props) {
  const {
    email,
    name,
    jobTitle,
    averageRating,
    numberOfLikes,
    image,
    _rawBio,
    location,
    slug,
    feeStructure,
    hideFeeStructure,
    qualification,
    specialisations,
    dictionary,
    dashboardId,
    widgets,
    awards,
    awardTitle,
    awardImage,
    company,
    isVisibleAdviser
  } = props;
  let direction = "row";
  const classes = useStyles();
  const [likes, setLikes] = useState(0);
  const [questions, setQuestions] = useState([]);
  const breadcrumbs = getAdviserPageBreadcrumbs()

  var companySlug = ""
  var folder = ""
  var fullCompanySlug = "#"

  const { companyDisplayName, companyLogo, reviewReference } = company || {}
  if ((company) && (reviewReference)) {
    companySlug = reviewReference.slug.current
    folder = reviewReference.subfolder.folder
    fullCompanySlug = folder.concat(companySlug);
  }

  useEffect(() => {
    (async () => {

    })();
  }, []);

  useEffect(() => {
    (async () => {
      var adviserQuestions = await getAdviserQuestions(dashboardId);

      if (typeof adviserQuestions !== "undefined") {
        setQuestions(adviserQuestions);
      }
    })();
  }, []);
  let mainImageWithType = image;

  if (image === null) {
    let mainImageWithType = null;
  } else {
    mainImageWithType._type = "mainImage";
    mainImageWithType.isVisibleAdviserFilteredImage = true;
  }

  return (
    <article>
      { (true) && <ContainerForPages isCoursePage={isVisibleAdviser ? true : false}>
        <Container>
          <Grid className={classes.grey}>
            <Hidden smDown implementation="css">
              <ForumBreadcrumb pageBreadcrumb={breadcrumbs} />
            </Hidden>

            <Grid container className={classes.greyInner} direction={direction}>
              <Grid item className={classes.content}>
                <Grid container className={classes.profile}>
                  <Grid xs={4} item className={classes.imageHolder}>
                    <BasePortableText
                      className={classes.imageContainer}
                      blocks={mainImageWithType}
                      serializers={serializers}
                      {...clientConfig.sanity}
                    />
                  </Grid>
                  <Grid xs={8} container item className={classes.profileText}>
                    <Grid>
                      <Typography variant="h2" className={classes.nameText}>
                        {name}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={
                          averageRating != undefined &&
                            averageRating != null &&
                            averageRating > 0
                            ? classes.jobTitleLessPaddingBottom
                            : classes.jobTitle
                        }
                      >
                        {jobTitle} {company ? `at ${companyDisplayName}` : null}
                      </Typography>
                      <ContainerForSVG>
                        <img src={LocationIcon} alt="LocationIcon" />
                        <Typography
                          variant="body1"
                          className={classes.location}
                        >
                          {location && location.location}
                        </Typography>
                      </ContainerForSVG>
                    </Grid>
                    <Hidden mdDown implementation="js">
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        className={
                          averageRating != undefined &&
                            averageRating != null &&
                            averageRating > 0
                            ? classes.likesButtonContainerLessPaddingTop
                            : classes.likesButtonContainer
                        }
                      >
                        <Grid
                          container
                          direction="row"
                          alignItems="flex-end"
                          justify="space-between"
                          className={
                            averageRating != undefined &&
                              averageRating != null &&
                              averageRating > 0
                              ? classes.likesButtonContainerNoPaddingTop
                              : classes.likesButtonContainer
                          }
                        >
                          <Grid item xs={5}>
                            <AdviserLikes
                              amountOfLikes={numberOfLikes}
                              averageRating={averageRating}
                              isAdviserPage={true}
                              isVisibleAdviser={true}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Grid
                              container
                              alignItems="flex-end"
                              justify="flex-end"
                            >
                              <Grid item>
                                <Hidden mdDown implementation="js">
                                  <Dialog
                                    dashboardId={dashboardId}
                                    buttonText={
                                      dictionary.nodes.find(
                                        (x) =>
                                          x.key ===
                                          "adviser_profile_contact_button_text"
                                      ).value
                                    }
                                    isCoursePage={true}
                                    isAdviserProfile={true}
                                    fullWidthButtonContainer={true}
                                    dashboardId={dashboardId}
                                    pinkCta={undefined}
                                    halfButtonPaddingTop={undefined}
                                    dispatch={undefined}
                                    isRecommendedAdviserButton={undefined}
                                  />
                                </Hidden>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Grid>
                  <Grid xs={12} item>
                    <Grid
                      container
                      lg={3}
                      md={12}
                      sm={12}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      className={classes.contactMeButtonContainer}
                    >
                      <Hidden lgUp implementation="js">
                        <AdviserLikesMobile
                          amountOfLikes={numberOfLikes}
                          averageRating={averageRating}
                          isVisibleAdviser={true}
                        />

                        <Dialog
                          dashboardId={dashboardId}
                          buttonText={
                            dictionary.nodes.find(
                              (x) =>
                                x.key === "adviser_profile_contact_button_text"
                            ).value
                          }
                          isAdviserProfile={true}
                          isCoursePage={true}
                          fullWidthButtonContainer={true}
                          dashboardId={dashboardId}
                          pinkCta={undefined}
                          halfButtonPaddingTop={undefined}
                          dispatch={undefined}
                          isRecommendedAdviserButton={undefined}
                          halfButtonPaddingTop={true}
                        />
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>

                <Typography variant="h2" className={classes.aboutMe}>
                  {
                    dictionary.nodes.find(
                      (x) => x.key === "adviser_profile_biography_title"
                    ).value
                  }
                </Typography>
                {_rawBio && <div className={classes.peopleBioWrapper}><PortableText blocks={_rawBio} /></div>}
              </Grid>

              <Grid className={classes.mobileFullwidth}>
                <Grid item sm={0} className={classes.sidebar}>
                  <Grid className={classes.headerContainer}>
                    <Typography variant="h4" className={classes.sidebarTitle}>
                      {
                        dictionary.nodes.find(
                          (x) => x.key === "adviser_profile_side_information_title"
                        ).value
                      }
                    </Typography>
                  </Grid>
                  <Grid className={classes.sidebarBlock}>
                    <Typography variant="body1" className={classes.sidebarSubtitle}>
                      {"I like helping people with"}
                    </Typography>
                    {specialisations.map(({ specialisation }) => (
                      <Grid key={specialisation} className={classes.sidebarMenuItem}>
                        <ContainerForSVG>
                          <img
                            src={TickYesIcon}
                            alt="TickYesIcon"
                            className={classes.iconSidebar}
                          />{" "}
                          <Typography variant="body2" className={classes.sidebarText80Percent}>
                            {specialisation}
                          </Typography>
                        </ContainerForSVG>
                      </Grid>
                    ))}
                  </Grid>

                  <Grid className={classes.sidebarBlock}>
                    <Typography variant="body1" className={classes.sidebarSubtitle}>
                      {
                        dictionary.nodes.find(
                          (x) =>
                            x.key ===
                            "adviser_profile_side_information_qualification_text"
                        ).value
                      }
                    </Typography>
                    {qualification.map(({ qualification }) => (
                      <Grid key={qualification} className={classes.sidebarMenuItem}>
                        <ContainerForSVG>
                          <img
                            src={TickYesIcon}
                            alt="TickYesIcon"
                            className={classes.iconSidebar}
                          />{" "}
                          <Typography variant="body2" className={classes.sidebarText80Percent}>
                            {qualification}
                          </Typography>
                        </ContainerForSVG>
                      </Grid>
                    ))}
                  </Grid>

                  {!hideFeeStructure && <Grid className={classes.sidebarBlock}>
                    <Typography variant="body1" className={classes.sidebarSubtitle}>
                      {
                        dictionary.nodes.find(
                          (x) => x.key === "adviser_profile_side_information_fee_text"
                        ).value
                      }
                    </Typography>
                    {feeStructure.map((text) => (
                      <Grid key={text} className={classes.sidebarMenuItem}>
                        <ContainerForSVG>
                          <img
                            src={TickYesIcon}
                            alt="TickYesIcon"
                            className={classes.iconSidebar}
                          />{" "}
                          <Typography variant="body2" className={classes.sidebarText80Percent}>
                            {text}
                          </Typography>
                        </ContainerForSVG>
                      </Grid>
                    ))}
                  </Grid>}
                </Grid>
                <Grid item sm={0} className={classes.itemsRightContainer}>
                  {awards && awards.length > 0 &&
                    <Grid item sm={0} className={classes.sideBarAwards}>
                      <PeopleAward awardTitle={awardTitle} awards={awards} awardImage={awardImage} isVisibleAdviser={true} />
                    </Grid>
                  }
                  {company &&
                    <Grid item sm={0} className={classes.sideBarCompany}>
                      <AdviserCompany companyDisplayName={companyDisplayName} companyLogo={companyLogo} linkHref={fullCompanySlug} />
                    </Grid>
                  }
                </Grid>


                {
                  widgets.length > 0 && (
                    <Grid item sm={0} className={classes.widgetsContainer}>
                      <WidgetComponent widgets={widgets} />
                    </Grid>
                  )}
              </Grid>
              <Grid container className={classes.contactRateContainer}>
                <Grid item className={classes.mobileSixtyFive}>
                  <Dialog
                    dashboardId={dashboardId}
                    buttonText={
                      dictionary.nodes.find(
                        (x) => x.key === "adviser_profile_contact_button_text"
                      ).value
                    }
                    isAdviserProfile={true}
                    isCoursePage={true}
                    fullWidthButtonContainer={true}
                    dashboardId={dashboardId}
                    pinkCta={undefined}
                    halfButtonPaddingTop={undefined}
                    dispatch={undefined}
                    isRecommendedAdviserButton={undefined}
                  />
                </Grid>
                <Grid
                  item
                  direction="row"
                  alignItems="center"
                  justify="center"
                  className={classes.mobileSixtyFive}
                >
                  <RateAdviserDialog
                    buttonText={
                      // dictionary.nodes.find(
                      //   (x) => x.key === "adviser_profile_rate_button_text"
                      // ).value
                      "Leave a Review"
                    }
                    dashboardId={dashboardId}
                    adviserName={name}
                    isCoursePage={true}
                    fullWidthButtonContainer={true}
                    dashboardId={dashboardId}
                    pinkCta={undefined}
                    halfButtonPaddingTop={undefined}
                    dispatch={undefined}
                    isRecommendedAdviserButton={undefined}
                  />
                </Grid>
              </Grid>

              <Container />
            </Grid>
          </Grid>
        </Container>
            <AdviserPosts autoscroll={true} dashboardId={dashboardId} />
        <Container>
        <Grid>
            <Grid>
              {questions.length > 0 && (
                <Typography variant="h2" className={classes.aboutMe}>
                  Check out my answers to reader questions on the main Boring Money website
                </Typography>
              )}
              <FilteredQuestionsAdviser dashboardId={dashboardId} isCoursePage={true}/>
            </Grid>
          </Grid>
          <Hidden smDown implementation="js">
            <AdviserPageReviews
              dashboardId={dashboardId}
              dictionary={dictionary}
              itemsPerPage={3}
              isCoursePage={true}
            />
          </Hidden>
          <Hidden mdUp implementation="js">
            <AdviserPageReviews
              dashboardId={dashboardId}
              dictionary={dictionary}
              itemsPerPage={2}
              isCoursePage={true}
            />
          </Hidden>
          <Hidden smDown implementation="js">
            <Grid item className={classes.adContent}>
              <WidgetComponent widgets={widgets} target="bottom" />
            </Grid>
          </Hidden>
        </Container>
        <Grid md={10} xs={11} item justify="center">
          <FooterDisclaimer isCoursePage={true} />
        </Grid>
      </ContainerForPages>}
    </article>
  );
}

export default BlogPost;
